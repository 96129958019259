define("ember-leaflet/components/base-layer", ["exports", "ember-composability-tools", "ember-invoke-action"], function (_exports, _emberComposabilityTools, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /* global L */
  var leaf = typeof L === 'undefined' ? {} : L;

  var _default = Ember.Component.extend(_emberComposabilityTools.ChildMixin, _emberInvokeAction.InvokeActionMixin, {
    tagName: '',
    L: leaf,
    fastboot: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      return owner.lookup('service:fastboot');
    }),
    isFastBoot: Ember.computed('fastboot', function () {
      return this.get('fastboot') && this.get('fastboot.isFastBoot');
    }),
    concatenatedProperties: ['leafletOptions', 'leafletRequiredOptions', 'leafletEvents', 'leafletProperties'],
    leafletOptions: Object.freeze(['pane', 'attribution']),
    leafletEvents: Object.freeze(['add', 'remove']),
    createLayer: function createLayer() {
      (false && !(false) && Ember.assert('BaseLayer\'s `createLayer` should be overriden.'));
    },
    didCreateLayer: function didCreateLayer() {},
    willDestroyLayer: function willDestroyLayer() {},

    /*
     * Method called by parent when the layer needs to setup
     */
    didInsertParent: function didInsertParent() {
      // Check for fastBoot
      if (this.get('isFastBoot')) {
        return;
      }

      this._layer = this.createLayer();

      this._addObservers();

      this._addEventListeners();

      if (this.get('parentComponent')) {
        this.addToContainer();
      }

      this.didCreateLayer();
    },

    /*
     * Default logic for adding the layer to the container
     */
    addToContainer: function addToContainer() {
      this.get('parentComponent')._layer.addLayer(this._layer);
    },

    /*
     * Method called by parent when the layer needs to teardown
     */
    willDestroyParent: function willDestroyParent() {
      // Check for fastBoot
      if (this.get('isFastBoot')) {
        return;
      }

      this.willDestroyLayer();

      this._removeEventListeners();

      this._removeObservers();

      if (this.get('parentComponent') && this._layer) {
        this.removeFromContainer();
      }

      delete this._layer;
    },

    /*
     * Default logic for removing the layer from the container
     */
    removeFromContainer: function removeFromContainer() {
      this.get('parentComponent')._layer.removeLayer(this._layer);
    },
    options: Ember.computed(function () {
      var _this = this;

      var leafletOptions = this.get('leafletOptions');
      var options = {};
      leafletOptions.forEach(function (optionName) {
        if (_this.get(optionName) !== undefined) {
          options[optionName] = _this.get(optionName);
        }
      });
      return options;
    }),
    leafletRequiredOptions: Ember.A(),
    requiredOptions: Ember.computed(function () {
      var _this2 = this;

      var leafletRequiredOptions = this.get('leafletRequiredOptions');
      var options = [];
      leafletRequiredOptions.forEach(function (optionName) {
        (false && !(_this2.get(optionName)) && Ember.assert("`".concat(optionName, "` is a required option but its value was `").concat(_this2.get(optionName), "`"), _this2.get(optionName)));
        options.push(_this2.get(optionName));
      });
      return options;
    }),
    usedLeafletEvents: Ember.computed('leafletEvents', function () {
      var _this3 = this;

      return this.get('leafletEvents').filter(function (eventName) {
        var methodName = "_".concat(eventName);
        var actionName = "on".concat(Ember.String.classify(eventName));
        return _this3.get(methodName) !== undefined || _this3.get(actionName) !== undefined;
      });
    }),
    _addEventListeners: function _addEventListeners() {
      var _this4 = this;

      this._eventHandlers = {};
      this.get('usedLeafletEvents').forEach(function (eventName) {
        var actionName = "on".concat(Ember.String.classify(eventName));
        var methodName = "_".concat(eventName); // create an event handler that runs the function inside an event loop.

        _this4._eventHandlers[eventName] = function (e) {
          var _this5 = this;

          Ember.run.scheduleOnce('actions', function () {
            // try to invoke/send an action for this event
            _this5.invokeAction(actionName, e); // allow classes to add custom logic on events as well


            if (typeof _this5[methodName] === 'function') {
              _this5[methodName](e);
            }
          });
        };

        _this4._layer.addEventListener(eventName, _this4._eventHandlers[eventName], _this4);
      });
    },
    _removeEventListeners: function _removeEventListeners() {
      var _this6 = this;

      if (this._eventHandlers) {
        this.get('usedLeafletEvents').forEach(function (eventName) {
          _this6._layer.removeEventListener(eventName, _this6._eventHandlers[eventName], _this6);

          delete _this6._eventHandlers[eventName];
        });
      }
    },
    leafletProperties: Ember.A(),
    _addObservers: function _addObservers() {
      var _this7 = this;

      this._observers = {};
      this.get('leafletProperties').forEach(function (propExp) {
        var _propExp$split = propExp.split(':'),
            _propExp$split2 = _toArray(_propExp$split),
            property = _propExp$split2[0],
            leafletProperty = _propExp$split2[1],
            params = _propExp$split2.slice(2);

        if (!leafletProperty) {
          leafletProperty = "set".concat(Ember.String.classify(property));
        }

        var objectProperty = property.replace(/\.\[]/, ''); // allow usage of .[] to observe array changes

        _this7._observers[property] = function () {
          var _this8 = this,
              _this$_layer$leafletP;

          var value = this.get(objectProperty);
          (false && !(!!this._layer[leafletProperty]) && Ember.assert("".concat(this.constructor, " must have a ").concat(leafletProperty, " function."), !!this._layer[leafletProperty]));
          var propertyParams = params.map(function (p) {
            return _this8.get(p);
          });

          (_this$_layer$leafletP = this._layer[leafletProperty]).call.apply(_this$_layer$leafletP, [this._layer, value].concat(_toConsumableArray(propertyParams)));
        };

        _this7.addObserver(property, _this7, _this7._observers[property]);
      });
    },
    _removeObservers: function _removeObservers() {
      var _this9 = this;

      if (this._observers) {
        this.get('leafletProperties').forEach(function (propExp) {
          var _propExp$split3 = propExp.split(':'),
              _propExp$split4 = _slicedToArray(_propExp$split3, 1),
              property = _propExp$split4[0];

          _this9.removeObserver(property, _this9, _this9._observers[property]);

          delete _this9._observers[property];
        });
      }
    }
  });

  _exports.default = _default;
});