define('ember-power-calendar/utils/date-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.add = add;
  exports.formatDate = formatDate;
  exports.startOf = startOf;
  exports.endOf = endOf;
  exports.weekday = weekday;
  exports.isoWeekday = isoWeekday;
  exports.getWeekdaysShort = getWeekdaysShort;
  exports.getWeekdaysMin = getWeekdaysMin;
  exports.getWeekdays = getWeekdays;
  exports.isAfter = isAfter;
  exports.isBefore = isBefore;
  exports.isSame = isSame;
  exports.isBetween = isBetween;
  exports.diff = diff;
  exports.normalizeDate = normalizeDate;
  exports.normalizeRangeActionValue = normalizeRangeActionValue;
  exports.normalizeMultipleActionValue = normalizeMultipleActionValue;
  exports.normalizeCalendarDay = normalizeCalendarDay;
  exports.withLocale = withLocale;
  exports.normalizeCalendarValue = normalizeCalendarValue;
  exports.normalizeDuration = normalizeDuration;
  exports.getDefaultLocale = getDefaultLocale;
  function add() /*date, quantity, unit*/{
    throw new Error('Not implemented');
  }

  function formatDate() /*date, format, locale = null*/{
    throw new Error("Not implemented");
  }

  function startOf() /*date, unit*/{
    throw new Error('Not implemented');
  }

  function endOf() /*date, unit*/{
    throw new Error('Not implemented');
  }

  function weekday() /*date*/{
    throw new Error('Not implemented');
  }

  function isoWeekday() /*date*/{
    throw new Error('Not implemented');
  }

  function getWeekdaysShort() /**/{
    throw new Error('Not implemented');
  }

  function getWeekdaysMin() /**/{
    throw new Error('Not implemented');
  }

  function getWeekdays() /**/{
    throw new Error('Not implemented');
  }

  function isAfter() /*date1, date2*/{
    throw new Error('Not implemented');
  }

  function isBefore() /*date1, date2*/{
    throw new Error('Not implemented');
  }

  function isSame() /*date1, date2, unit*/{
    throw new Error('Not implemented');
  }

  function isBetween() /*date, start, end, unit, inclusivity*/{
    throw new Error('Not implemented');
  }

  function diff() /*date1, date2*/{
    throw new Error('Not implemented');
  }

  function normalizeDate() /*dateOrMoment*/{
    throw new Error("Not implemented");
  }

  function normalizeRangeActionValue() /*val*/{
    throw new Error("Not implemented");
  }

  function normalizeMultipleActionValue() /*val*/{
    throw new Error("Not implemented");
  }

  function normalizeCalendarDay() /*day*/{
    throw new Error("Not implemented");
  }

  function withLocale() /*locale, fn*/{
    throw new Error("Not implemented");
  }

  function normalizeCalendarValue() /*value*/{
    throw new Error("Not implemented");
  }

  function normalizeDuration() /*value*/{
    throw new Error("Not implemented");
  }

  function getDefaultLocale() /**/{
    throw new Error("Not implemented");
  }
});