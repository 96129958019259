// Support for 3.1 - 3.2
var _Ember$__loader$requi = Ember.__loader.require('ember-glimmer'),
    _registerMacros = _Ember$__loader$requi._registerMacros;

_registerMacros(function (blocks) {
  blocks.add('let', function (params, _hash, template, _inverse, builder) {
    if (template !== null) {
      if (params !== null) {
        builder.compileParams(params);
        builder.invokeStaticBlock(template, params.length);
      } else {
        builder.invokeStatic(template);
      }
    }
    return true;
  });
});