define("ember-leaflet/components/marker-layer", ["exports", "ember-leaflet/components/interactive-layer", "ember-leaflet/mixins/draggability", "ember-leaflet/mixins/div-overlayable", "ember-leaflet/macros/to-lat-lng"], function (_exports, _interactiveLayer, _draggability, _divOverlayable, _toLatLng) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _interactiveLayer.default.extend(_draggability.default, _divOverlayable.default, {
    leafletRequiredOptions: Object.freeze(['location']),
    leafletOptions: Object.freeze(['icon', 'clickable', 'draggable', 'keyboard', 'title', 'alt', 'zIndexOffset', 'opacity', 'riseOnHover', 'riseOffset']),
    leafletEvents: Object.freeze(['dragstart', 'drag', 'dragend', 'move', 'moveend', 'remove', 'add', 'popupopen', 'popupclose']),
    leafletProperties: Object.freeze(['zIndexOffset', 'opacity', 'location:setLatLng']),
    location: (0, _toLatLng.default)(),
    createLayer: function createLayer() {
      var _this$L;

      return (_this$L = this.L).marker.apply(_this$L, _toConsumableArray(this.get('requiredOptions')).concat([this.get('options')]));
    },
    // icon observer separated from generated (leaflet properties) due to a
    // leaflet bug where draggability is lost on icon change
    // eslint-disable-next-line ember/no-observers
    iconDidChange: Ember.observer('icon', function () {
      this._layer.setIcon(this.get('icon'));

      if (this.get('draggable')) {
        this._layer.dragging.enable();
      } else {
        this._layer.dragging.disable();
      }
    })
  });

  _exports.default = _default;
});